import { useRef, useState, useEffect } from "react"
import { useMediaQuery } from 'react-responsive'
import { CheckoutState } from '@/components/Checkout/enum/CheckoutState'

type BTCheckoutProps = {
	eventId: string
	cartId?: string
	elementId: string
	width?: string|number|undefined
	height?: string|number|undefined
	minWidth?: string|number|undefined
	minHeight?: string|number|undefined
	onStateChange?: Function
	extraStyle?: any,
	compact?: boolean,
	autoSizing?: boolean,
	darkMode?: boolean
}

export default function BTCheckout({
	elementId,
	eventId,
	cartId,
	width,
	height,
	minWidth = "300px",
	minHeight = "370px",
	onStateChange = (state: CheckoutState) => {},
	extraStyle,
	compact = false,
	autoSizing = false,
	darkMode = false
}: BTCheckoutProps) {
	const frameRef = useRef<HTMLIFrameElement>(null)
	const [ currentState, setCurrentState ] = useState(null)
	const origin = process.env.NEXT_PUBLIC_CHECKOUT_URL
	let url = new URL(process.env.NEXT_PUBLIC_CHECKOUT_URL+"/checkout");
	url.searchParams.set("eventId", eventId)
	url.searchParams.set("elemId", elementId)
	if(cartId) {
		url.searchParams.set("cartId", cartId)
	}
	if(compact) {
		url.searchParams.set("compact", "1")
	}
	if(darkMode) {
		url.searchParams.set("darkMode", "1")
	}

	useEffect(function () {
		const handleMessage = (event: MessageEvent) => {
			if(event.origin != origin) { return }

			if(event.data.type == "bt-state-change") {
				if(elementId != event.data.elemId) { return }
				onStateChange(event.data)
				setCurrentState(event.data.state)
			}

			if(autoSizing && event.data.type == "bt-size-update" && frameRef.current != null) {
				if(elementId != event.data.elemId) { return }
				frameRef.current.width = (width) ? width : event.data.width
				frameRef.current.height = (height) ? height : event.data.height
			}
		};

		window.addEventListener("message", handleMessage);
		return function () {
			window.removeEventListener("message", handleMessage);
		};
	}, []);

	return (
		<iframe
			ref={frameRef}
			style={{
				padding: 0,
				margin: 0,
				border: 0,
				minWidth: minWidth,
				minHeight: minHeight,
				backgroundColor: (darkMode) ? "#3f3f3f" : "white",
				...extraStyle
			}}
			width={width}
			height={height}
			src={url.toString()}
			allow="payment"
		/>
	)
}

export function BTCheckoutOverlay({
	elementId,
	eventId,
	cartId,
	onStateChange = (state: CheckoutState) => {}
}: BTCheckoutProps) {
	const isTabletOrMobile = useMediaQuery({ maxWidth: 860 })

	let mystyle = {
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(0,0,0,0.6)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	}

	return (<div style={mystyle}>
		<BTCheckout
			elementId={elementId}
			eventId={eventId}
			cartId={cartId}
			onStateChange={onStateChange}
			height={(isTabletOrMobile) ? "100%" : "85%"}
			width={(isTabletOrMobile) ? "100%" : "60%"}
			extraStyle={{
				borderRadius: (isTabletOrMobile) ? null : "0.25rem"
			}}
		/>
	</div>)
}
