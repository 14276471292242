import ReactDOM from 'react-dom/client';
import BTCheckout, { BTCheckoutOverlay } from '../components/BTCheckout/BTCheckout';
import { CheckoutState } from '../components/Checkout/enum/CheckoutState'

window.BTCheckout = {
	createEmbedded: ({
		elementId,
		eventId,
		width,
		height
	}) => {
		const elem = document.getElementById(elementId)

		const checkoutWidth = width ?? "100%"
		const checkoutHeight = height ?? "450px"

		const root = ReactDOM.createRoot(elem);
		root.render(
			<BTCheckout
				elementId={elementId}
				eventId={eventId}
				width={checkoutWidth}
				height={checkoutHeight}
			/>
		);
	},

	createCompact: ({
		elementId,
		eventId,
		width,
		height,
		darkMode
	}) => {
		let cartId = ""
		let bodyElem = null

		const elem = document.getElementById(elementId)
		
		const checkoutWidth = width ?? null
		const checkoutHeight = height ?? null

		const onStateChange = (data) => {
			cartId = data.cartId
			if(data.state == CheckoutState.CHECKOUT) {
				mountBody()
			}

			if(data.state == CheckoutState.COLLECTING) {
				unmountBody()
			}
		}

		const mountBody = () => {
			if(bodyElem != null) { return }

			bodyElem = document.createElement("div")
			bodyElem.style.position = "fixed"
			bodyElem.style.top = "0px"
			bodyElem.style.bottom = "0px"
			bodyElem.style.left = "0px"
			bodyElem.style.right = "0px"
			bodyElem.style.zIndex = "9999999"

			document.body.appendChild(bodyElem);

			const root = ReactDOM.createRoot(bodyElem);
			root.render(
				<BTCheckoutOverlay
					elementId={elementId}
					eventId={eventId}
					cartId={cartId}
					onStateChange={onStateChange}
				/>
			);
		}

		const unmountBody = () => {
			if(bodyElem == null) { return }

			bodyElem.remove()
			bodyElem = null
		}

		const mountBase = () => {
			const root = ReactDOM.createRoot(elem);
			root.render(
				<BTCheckout
					elementId={elementId}
					eventId={eventId}
					width={checkoutWidth}
					height={checkoutHeight}
					minWidth="300px"
					minHeight="170px"
					onStateChange={onStateChange}
					compact={true}
					autoSizing={true}
					darkMode={(darkMode === true)}
				/>
			);
		}

		mountBase()
	}
}
